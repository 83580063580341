import { useEffect } from "react"
import { navigate } from "gatsby"

export default () => {
  useEffect(() => {
    navigate("#https://www.google.com")
  }, [])

  return null
}
